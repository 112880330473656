<template>
  <div>
    <van-search
      v-model="queryForm.KeyWord"
      shape="round"
      background="#2778F8"
      placeholder="请输入想要搜索的内容"
      @search="onSearch"
      @cancel="onCancel"
    />
    <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
      <van-list
        v-model="loading"
        :finished="finished"
        :offset="15"
        @load="onLoad"
      >
        <div>
          <div v-if="flag === true">
            <div
              v-for="(item, i) in list"
              :key="i"
              class="yun_newedition_resume"
            >
              <div class="yun_newedition_resumelist">
                <a @click="toInfo(item)">
                  <div class="yun_newedition_resume_wantjob">
                    <span class="yun_newedition_resume_wantjob_n">
                      {{ item.Shop_Name }}
                    </span>
                  </div>
                  <div class="user_undergo_box">
                    <div class="user_undergo user_undergo_a">
                      店铺地址：{{ item.Shop_Address }}
                    </div>
                  </div>
                  <div class="user_undergo_box">
                    <div class="user_undergo user_undergo_a">
                      走访时间：{{ item.Date_OP }}
                    </div>
                  </div>
                  <div class="user_undergo_box">
                    <div class="user_undergo user_undergo_a">
                      法定代表人：{{ item.Legal_Name }}
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
          <van-empty v-else description="暂无走访记录，请重新查询" />
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
  import store from '@/store'
  import moment from 'moment'
  import Base64 from '@/util/Base64'
  import { Toast } from 'vant'
  import { getShopViewList } from '@/api/outDoor/shopView/shopView'
  import { Emas } from '@/assets/js/Emas'

  export default {
    name: 'Index',
    data() {
      return {
        list: [],
        type: '',
        value: '',
        flag: true,
        loading: false, //是否处于加载状态
        finished: false, //是否已加载完所有数据
        isFinished: false,
        pageCount: '', //总页数
        date: '',
        queryForm: {
          intPageIndex: 1,
          intPageSize: 10,
          Shop_ID_Origin: 0,
          KeyWord: '',
        },
        isLoading: false,
        showPicker: false,
        showStatus: false,
        statusOptions: [
          {
            text: '请选择状态',
            value: 0,
          },
          {
            text: '进行中',
            value: 1,
          },
          {
            text: '结束',
            value: 2,
          },
        ],
      }
    },
    created() {
      this.queryForm.Shop_ID_Origin =
        this.$route.query.shopID === undefined
          ? 0
          : Base64.decode(this.$route.query.shopID)
      this.fetchData()
      let userInfo = JSON.parse(store.getters['user/userInfo'])
      let staffName = userInfo.Staff_Name
      let userId = userInfo.User_ID
      Emas(
        staffName,
        userId,
        'shopView',
        '城管-店铺走访列表',
        'zzdcg.yy.gov.cn/shopView'
      )
    },
    methods: {
      //页面动态加载方法
      onLoad() {
        this.loading = true
        //当页面数大于总页数时，停止加载请求数据
        if (this.queryForm.intPageIndex >= this.pageCount) {
          this.loading = false
          this.isFinished = true //数据加载完
        } else {
          this.queryForm.intPageIndex++
          this.fetchData()
        }
      },
      //获取数据
      async fetchData() {
        Toast.loading({
          duration: 3000, // 持续展示 toast
          message: '正在加载中...',
          forbidClick: true,
        })
        const { data } = await getShopViewList(this.queryForm)
        Toast.clear()
        if (data.data.length > 0) {
          this.flag = true
          this.pageCount = data.pageCount
          this.list = this.list.concat(data.data)
          this.loading = false
        } else {
          this.flag = false
          this.loading = false //不处于加载状态
          this.isFinished = true //数据加载完，不会再处于加载状态
        }
      },

      //跳转至详情
      toInfo(item) {
        this.$router.push({
          path: 'ViewInfo',
          query: {
            shopView_ID: Base64.encode(item.ID),
          },
        })
      },

      onSearch(val) {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.KeyWord = val
        this.fetchData()
      },
      onCancel() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.KeyWord = ''
        this.fetchData()
      },

      onRefresh() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.fetchData()
        this.isLoading = false
      },

      //格式化时间
      reset() {
        this.list = []
        this.queryForm.intPageIndex = 1
        this.queryForm.KeyWord = ''
        this.fetchData()
      },
    },
  }
</script>

<style scoped>
  .yun_newedition_resume_wantjob {
    padding-bottom: 0.266666rem;
    font-size: 0.426666rem;
    color: #666;
  }
  .yun_newedition_resume_wantjob_n {
    font-size: 0.426666rem;
    font-weight: bold;
    color: #000;
  }
  .yun_newedition_resumelist a {
    position: relative;
    display: block;
    padding: 0.4rem 0.4rem 0.4rem 0.4rem;
  }
  .new_userlist_p {
    position: relative;
    padding-bottom: 0.266666rem;
    font-size: 0.346666rem;
    color: #333;
  }
  .user_undergo {
    width: 100%;
    height: 0.8rem;
    overflow: hidden;
    font-size: 0.346666rem;
    line-height: 0.8rem;
    color: #333;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .yun_newedition_resume {
    padding: 0rem 0.32rem 0rem 0.32rem;
  }
  .yun_newedition_resumelist {
    margin-top: 0.266666rem;
    background: #fff;
    border-radius: 0.213333rem;
  }
  .user_undergo_box {
    padding: 0rem 0.4rem 0rem 0rem;
  }
  .user_undergo_a {
    position: relative;
    padding-left: 0.666666rem;
  }
  .user_undergo_a::after {
    position: absolute;
    top: 0.16rem;
    left: 0rem;
    display: inline-block;
    width: 0.426666rem;
    height: 0.426666rem;
    content: '';
    background: url(../../../assets/images/workBench/advise/icon.png) no-repeat;
    background-size: auto;
    background-size: 100%;
  }
</style>
